import { useState, useEffect } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./History3.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import LottieAnimation from "../../comm/global/components/LottieAnimation";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const History3 = ({
  anserHistory,
  AllOptionKey,
  allQuestion,
  enterResultFromHistory,
}) => {
  const { load } = useGlobalAudioPlayer();
  const [result, set_result] = useState(null);
  const [audioPlayingIndex, set_audioPlayingIndex] = useState(-1);

  useEffect(() => {
    console.log("anserHistory", anserHistory);
    const currentQuestion = allQuestion[0];
    let options = [];
    AllOptionKey.forEach((key) => {
      if (
        currentQuestion &&
        currentQuestion[key] &&
        currentQuestion[key] !== ""
      ) {
        options.push(currentQuestion[key]);
      }
    });
    const anserZone = currentQuestion.questionAnswerData.split(",");
    console.log("options", options);
    console.log("anserZone", anserZone);
    const viewData = [];
    anserZone.forEach((id) => {
      const obj = anserHistory.find((v) => v.vo.id === parseInt(id));
      viewData.push(obj);
    });
    console.log("viewData", viewData);
    set_result({ viewData });
  }, [AllOptionKey, allQuestion, anserHistory]);

  if (result)
    return (
      <div className={style.history}>
        <BackgroundDiv className={style.bg} img="./game3/history_bg.png">
          <div className={style.title}>
            <span>正確答案</span>
          </div>
          <div className={style.tip}>
            <div>
              <img src="./game2/right.svg" alt="right icon" />
              <span>答對</span>
            </div>
            <div>
              <img src="./game2/wrong.svg" alt="wrong icon" />
              <span>答錯</span>
            </div>
          </div>
          <div className={style.content}>
            {result.viewData.map((option, k) => {
              return (
                <div className={style.itemBox} key={k}>
                  <div
                    className={style.item}
                    onClick={(e) => {
                      if (option.vo.type !== "voice") return;
                      set_audioPlayingIndex(option.vo.id);
                      load(`${AppConfig.baseUrl}${option.vo.value}`, {
                        autoplay: true,
                        volume: 1,
                        onend: (e) => {
                          set_audioPlayingIndex(-1);
                        },
                      });
                    }}
                  >
                    {option.vo.type === "voice" ? (
                      <>
                        <div className={style.audioIcon}>
                          {option.vo.id === audioPlayingIndex ? (
                            <LottieAnimation color="blue" />
                          ) : (
                            <img src="./comm/play2.svg" alt="play 2" />
                          )}
                        </div>
                        <span>{`選項${option.vo.number}`}</span>
                      </>
                    ) : (
                      <span
                        style={{ fontFamily: getFontFamily(option.vo.type) }}
                      >
                        {option.vo.value}
                      </span>
                    )}
                    {option.isCorrect === true && (
                      <img
                        className={style.checkImg}
                        src="./game2/right.svg"
                        alt="right icon"
                      />
                    )}
                    {option.isCorrect === false && (
                      <img
                        className={style.checkImg}
                        src="./game2/wrong.svg"
                        alt="wrong icon"
                      />
                    )}
                  </div>
                  {k < result.viewData.length - 1 && (
                    <img
                      className={style.arrowDown}
                      src="./game3/caret_down_solid.png"
                      alt="arrow down"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </BackgroundDiv>
        <div className={style.backDiv}>
          <div
            className={style.back}
            onClick={() => {
              enterResultFromHistory();
              syncService.sendMessage(SyncEvent.ENTER_RESULT_FROM_HISTORY);
            }}
          >
            <span>返回</span>
          </div>
        </div>
      </div>
    );
};

export default History3;
