import React, { useEffect, useRef } from "react";
import parseAPNG from "apng-js";

const ApngImg = ({
  src,
  alt,
  autoPlay = true,
  loopTimes = 0,
  delaySec = 0,
  doneCb,
  ...imgProps
}) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const imgElement = imgRef.current;

    // Fetch the APNG image
    fetch(src)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const apng = parseAPNG(buffer);
        if (apng instanceof Error) {
          console.error("Failed to load APNG:", apng);
          return;
        }
        apng.numPlays = loopTimes;
        apng.createImages().then(() => {
          const canvas = document.createElement("canvas");
          canvas.width = apng.width;
          canvas.height = apng.height;

          imgElement.replaceWith(canvas);

          apng.getPlayer(canvas.getContext("2d")).then((player) => {
            player.playbackRate = 1.0;
            player.stop();
            player.on("end", () => {
              if (doneCb) {
                doneCb();
              }
            });
            if (autoPlay) {
              setTimeout(() => {
                player.play();
              }, delaySec * 1000);
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error fetching APNG:", error);
      });
  }, [src, delaySec]);

  return <img ref={imgRef} src={src} {...imgProps} style={{ width: "0px" }} />;
};

export default ApngImg;
