import { useState, useEffect, useRef, useCallback } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Game3.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily, wait } from "../../comm/comm";
import DragDropContext from "../DragDropContext";
import { useDrag, useDrop, DndProvider, useDragLayer } from "react-dnd";
import { HTML5Backend, getEmptyImage } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import LottieAnimation from "../../comm/global/components/LottieAnimation";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const ItemType = "SORTABLE_ITEM";

const Game3 = ({
  end,
  pause,
  AllOptionKey,
  allQuestion,
  set_questionNum,
  set_rightCount,
  set_anserHistory,
  enterResultFromGame3,
}) => {
  const [items, setItems] = useState(null);
  const anser = useRef([]);
  const [state, set_state] = useState("gaming");
  const [dragEnabled, setDragEnabled] = useState(false);
  const [aniEnd, set_aniEnd] = useState(false);
  const currentQuestion = allQuestion[0];
  const [audioPlayingIndex, set_audioPlayingIndex] = useState(-1);

  const submit = useCallback(() => {
    set_state("check");
    pause.current = true;
    setDragEnabled(false);
    let rightCount = 0;
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const em = document.querySelector(`#item_${itemIndex}`);
      em.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      console.log(
        "parseInt(items[itemIndex].id) === anser.current[itemIndex]",
        parseInt(items[itemIndex].id),
        anser.current[itemIndex],
      );
      if (
        parseInt(items[itemIndex].id) === parseInt(anser.current[itemIndex])
      ) {
        rightCount++;
        set_anserHistory((prev) => [
          ...prev,
          { isCorrect: true, vo: items[itemIndex] },
        ]);
        em.classList.add(style.showRightImg);
      } else {
        set_anserHistory((prev) => [
          ...prev,
          { isCorrect: false, vo: items[itemIndex] },
        ]);
        em.classList.add(style.showWrongImg);
      }
    }
    set_rightCount(rightCount);
    set_state("result");
  }, [items, pause, set_anserHistory, set_rightCount]);

  const submitSync = useCallback(() => {
    syncService.sendMessage(SyncEvent.GAME_3_SUBMIT);
    submit();
  }, [submit]);

  const enterResultFromGame3Sync = useCallback(() => {
    enterResultFromGame3();
    syncService.sendMessage(SyncEvent.ENTER_RESULT_FROM_GAME_3);
  }, [enterResultFromGame3]);

  const moveItem = useCallback((fromIndex, toIndex) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const [movedItem] = updatedItems.splice(fromIndex, 1);
      updatedItems.splice(toIndex, 0, movedItem);
      return updatedItems;
    });
  }, []);

  const moveItemSync = useCallback(
    (fromIndex, toIndex) => {
      moveItem(fromIndex, toIndex);
      syncService.sendMessage(SyncEvent.GAME_2_MOVE_ITEM, {
        fromIndex,
        toIndex,
      });
    },
    [moveItem],
  );

  useEffect(() => {
    const cleanup = syncService.onMessage((event, payload) => {
      switch (event) {
        case SyncEvent.GAME_3_SUBMIT:
          submit();
          break;
        case SyncEvent.GAME_2_MOVE_ITEM:
          moveItem(payload.fromIndex, payload.toIndex);
          break;
        default:
          break;
      }
    });
    return () => cleanup();
  }, [submit, moveItem]);

  useEffect(() => {
    const currentQuestion = allQuestion[0];
    let options = [];
    AllOptionKey.forEach((key) => {
      if (
        currentQuestion &&
        currentQuestion[key] &&
        currentQuestion[key] !== ""
      ) {
        const id = parseInt(key.replace("option", ""));
        options.push({
          id: id,
          value: currentQuestion[key],
          type: currentQuestion[`optionType${id}`],
        });
      }
    });
    anser.current = currentQuestion.questionAnswerData.split(",");
    // options = shuffle(options);
    options.forEach((v, k) => {
      v.number = k + 1;
    });
    set_questionNum(options.length);
    setItems(options);
    setTimeout(() => {
      startAni();
    }, 1000);
  }, [AllOptionKey, allQuestion, set_questionNum]);

  useEffect(() => {
    if (end === true) {
      submitSync();
    }
  }, [end, submitSync]);

  const startAni = async () => {
    for (let i = 0; ; i++) {
      const em = document.querySelector(`#item_${i}`);
      if (!em) break;
      em.classList.add(style.front);
      await wait(200);
    }
    set_aniEnd(true);
    setDragEnabled(true);
  };

  const pos = useRef("");
  const moveStepY = 1;

  const CustomDragLayer = () => {
    const { item, isDragging, clientOffset } = useDragLayer((monitor) => ({
      itemType: monitor.getItemType(),
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      clientOffset: monitor.getClientOffset(),
    }));

    console.log("isDragging", isDragging);
    if (!isDragging) {
      document.querySelectorAll(".itembg").forEach((v) => {
        v.style.background = "#D0F2FF";
      });
      pos.current = "";
      return null;
    }
    const listDiv = document.querySelector("#list");
    const listRect = listDiv.getBoundingClientRect();
    if (clientOffset.y < listRect.y) {
      pos.current = "up";
    } else if (clientOffset.y > listRect.bottom) {
      pos.current = "down";
    } else pos.current = "";
    function step(timestamp) {
      console.log("step");
      if (pos.current === "up") {
        listDiv.scrollBy({
          top: -moveStepY,
          left: 0,
        });
        window.requestAnimationFrame(step);
      } else if (pos.current === "down") {
        listDiv.scrollBy({
          top: moveStepY,
          left: 0,
        });
        window.requestAnimationFrame(step);
      } else window.cancelAnimationFrame(step);
    }
    if (isDragging) window.requestAnimationFrame(step);
    else {
      console.log(">", isDragging, "cancel");
      window.cancelAnimationFrame(step);
    }
    const rect = document.body.getBoundingClientRect();
    const dy = (rect.height - rect.width * 0.61) / 2;
    const dx = (rect.width * 0.42) / 2;
    if (item.item.type === "voice") {
      return (
        <div
          className={`${style.floatItem} floatItem`}
          style={{
            position: "fixed",
            pointerEvents: "none",
            left: dx,
            top: clientOffset.y - dy,
          }}
        >
          <div className={style.audioIcon}>
            <img
              className={style.audioImg}
              src="./comm/play2.svg"
              alt="play 2 icon"
            />
          </div>
          <span>{`選項${item.item.number}`}</span>
          <img
            id="right"
            className={style.rightImg}
            src="./game2/right.svg"
            alt="right icon"
          />
          <img
            id="wrong"
            className={style.wrongImg}
            src="./game2/wrong.svg"
            alt="wrong icon"
          />
        </div>
      );
    } else
      return (
        <div
          className={`${style.floatItem} floatItem`}
          style={{
            position: "fixed",
            pointerEvents: "none",
            left: dx,
            top: clientOffset.y - dy,
          }}
        >
          <span style={{ fontFamily: getFontFamily(item.item.type) }}>
            {item.item.value}
          </span>
          <img
            id="right"
            className={style.rightImg}
            src="./game2/right.svg"
            alt="right icon"
          />
          <img
            id="wrong"
            className={style.wrongImg}
            src="./game2/wrong.svg"
            alt="wrong icon"
          />
        </div>
      );
  };
  if (items) {
    return (
      <DndProvider
        backend={
          "ontouchstart" in window || navigator.maxTouchPoints > 0
            ? TouchBackend
            : HTML5Backend
        }
      >
        <div className={style.app}>
          <BackgroundDiv className={style.list} img="./game3/game_bg.png">
            <div className={style.tip}>
              <span>{currentQuestion.questionDescription}</span>
            </div>
            <DragDropContext>
              <div className={style.sortList} onWheel={(e) => {}} id="list">
                {items.map((item, index) => (
                  <div key={index}>
                    <SortableItem
                      key={item.id}
                      item={item}
                      index={index}
                      moveItem={moveItemSync}
                      dragEnabled={dragEnabled}
                      aniEnd={aniEnd}
                      audioPlayingIndex={audioPlayingIndex}
                      set_audioPlayingIndex={set_audioPlayingIndex}
                    />
                  </div>
                ))}
              </div>
            </DragDropContext>
          </BackgroundDiv>
          {state === "gaming" && aniEnd === true && (
            <div className={style.submitBox}>
              <div className={style.submit} onClick={submitSync}>
                <span>開始對答</span>
              </div>
            </div>
          )}
          {state === "result" && (
            <div className={style.submitBox}>
              <div
                className={style.submit}
                onClick={() => enterResultFromGame3Sync()}
              >
                <span>查看遊戲結果</span>
              </div>
            </div>
          )}
          <CustomDragLayer />
        </div>
      </DndProvider>
    );
  }

  return null;
};

const SortableItem = ({
  item,
  index,
  moveItem,
  dragEnabled,
  aniEnd,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  const { load } = useGlobalAudioPlayer();
  const ref = useRef(null);
  const [selectedIndex, set_selectedIndex] = useState(false);
  const [, drop] = useDrop({
    accept: ItemType,
    hover(draggedItem) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
      set_selectedIndex(true);
    },
    drop(draggedItem, monitor) {
      // 這裡是當拖放結束後會被觸發的事件
      set_selectedIndex(false); // 拖放結束後可以取消選擇狀態
    },
  });

  const [, drag, preview] = useDrag({
    type: ItemType,
    item: { index, item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => dragEnabled,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(drop(ref));
  if (item.type === "voice") {
    return (
      <div
        ref={ref}
        id={`item_${index}`}
        className={`${style.flipCard} ${aniEnd ? style.front : ""}`}
      >
        {index > 0 && (
          <img
            className={style.arrowDown}
            src="./game3/caret_down_solid.png"
            alt="caret down"
          />
        )}
        <div className={`${style.flipCardInner} `}>
          <BackgroundDiv
            img="./game3/option.png"
            className={style.backCard}
          ></BackgroundDiv>
          <div
            className={`${style.item} itembg`}
            style={{
              background: /* isItemDragging &&  */ selectedIndex
                ? "#74CAFF"
                : "#D0F2FF",
            }}
            onClick={(e) => {
              set_audioPlayingIndex(item.id);
              load(`${AppConfig.baseUrl}${item.value}`, {
                autoplay: true,
                volume: 1,
                onend: (e) => {
                  console.log("done");
                  set_audioPlayingIndex(-1);
                },
              });
            }}
          >
            <div className={style.audioIcon}>
              {item.id === audioPlayingIndex ? (
                <LottieAnimation color="blue" />
              ) : (
                <img
                  className={style.audioImg}
                  src="./comm/play2.svg"
                  alt="play 2 icon"
                />
              )}
            </div>
            <span>{`選項${item.number}`}</span>
            <img
              id="right"
              className={style.rightImg}
              src="./game2/right.svg"
              alt="right icon"
            />
            <img
              id="wrong"
              className={style.wrongImg}
              src="./game2/wrong.svg"
              alt="wrong icon"
            />
          </div>
        </div>
      </div>
    );
  } else if (["text", "text_mps", "mps"].includes(item.type)) {
    return (
      <div
        ref={ref}
        id={`item_${index}`}
        className={`${style.flipCard} ${aniEnd ? style.front : ""}`}
      >
        {index > 0 && (
          <img
            className={style.arrowDown}
            src="./game3/caret_down_solid.png"
            alt="caret down icon"
          />
        )}
        <div className={`${style.flipCardInner} `}>
          <BackgroundDiv
            img="./game3/option.png"
            className={style.backCard}
          ></BackgroundDiv>
          <div
            className={`${style.item} itembg`}
            style={{ background: selectedIndex ? "#74CAFF" : "#D0F2FF" }}
          >
            <span style={{ fontFamily: getFontFamily(item.type) }}>
              {item.value}
              {selectedIndex}
            </span>
            <img
              id="right"
              className={style.rightImg}
              src="./game2/right.svg"
              alt="right icon"
            />
            <img
              id="wrong"
              className={style.wrongImg}
              src="./game2/wrong.svg"
              alt="wrong icon"
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Game3;
